import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getTongleWhiteListsSuccess,
  getTongleWhiteListsFailed,
  GET_TONGLE_WHITELIST_START,
} from '../../actions/whiteList'
import { api } from '~/services/api'

/**
 * get list whitelist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getToggleWhiteListsApi = (props) => {
  var uri = `/settings`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetTongleWhiteLists(action) {
  try {
    const response = yield call(getToggleWhiteListsApi, action?.payload)
    yield put(getTongleWhiteListsSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getTongleWhiteListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list whitelist
 */
export default function* watchGetTongleWhiteLists() {
  yield takeLatest(GET_TONGLE_WHITELIST_START, doGetTongleWhiteLists)
}
