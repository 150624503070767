import { call, put, takeLatest } from 'redux-saga/effects'
import {
  changeTongleWhiteListSuccess,
  changeTongleWhiteListFailed,
  CHANGE_TONGLE_WHITELIST_START,
} from '../../actions/whiteList'
import { api } from '~/services/api'

/**
 * delete blacklist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const changeWhiteListsApi = (params) => {
  const uri = `/settings`
  return api.post(uri, params)
}
/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doChangeWhiteLists(action) {
  try {
    const response = yield call(changeWhiteListsApi, action?.payload)
    yield put(changeTongleWhiteListSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(changeTongleWhiteListFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete blacklist
 */
export default function* watchChangeWhiteLists() {
  yield takeLatest(CHANGE_TONGLE_WHITELIST_START, doChangeWhiteLists)
}
