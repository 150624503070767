import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_TONGLE_WHITELIST_START,
  GET_TONGLE_WHITELIST_SUCCESS,
  GET_TONGLE_WHITELIST_FAILED,
  CHANGE_TONGLE_WHITELIST_START,
  CHANGE_TONGLE_WHITELIST_SUCCESS,
  CHANGE_TONGLE_WHITELIST_FAILED,
  GET_LIST_WHITELIST_START,
  GET_LIST_ADD_WHITELIST_START,
  CREATE_WHITELIST_START,
  DELETE_WHITELIST_START,
  GET_LIST_WHITELIST_SUCCESS,
  GET_LIST_ADD_WHITELIST_SUCCESS,
  CREATE_WHITELIST_SUCCESS,
  DELETE_WHITELIST_SUCCESS,
  GET_LIST_WHITELIST_FAILED,
  GET_LIST_ADD_WHITELIST_FAILED,
  CREATE_WHITELIST_FAILED,
  DELETE_WHITELIST_FAILED,
} from '../actions/whiteList'

const initialState = {
  isLoadingGetListWhiteList: false,
  isLoadingGetListAddWhiteList: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  listWhiteList: [],
  isTongle: {},
  isChange: {},
}

/**
 * White list reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function whiteListsManagement(state = initialState, action) {
  switch (action.type) {
    case GET_TONGLE_WHITELIST_START:
    case CHANGE_TONGLE_WHITELIST_START:
      return {
        ...state,
      }
    case GET_LIST_WHITELIST_START:
      return {
        ...state,
        isLoadingGetListWhiteList: true,
      }
    case GET_LIST_ADD_WHITELIST_START:
      return {
        ...state,
        isLoadingGetListAddWhiteList: true,
      }
    case CREATE_WHITELIST_START:
      return {
        ...state,
        isLoadingCreate: true,
      }
    case DELETE_WHITELIST_START:
      return {
        ...state,
        isLoadingDelete: true,
      }
    //
    case GET_TONGLE_WHITELIST_SUCCESS:
      return {
        ...state,
        isTongle: action?.payload?.data,
      }
    case CHANGE_TONGLE_WHITELIST_SUCCESS:
      return {
        ...state,
        isChange: action?.payload,
      }

    case GET_LIST_WHITELIST_SUCCESS:
      return {
        ...state,
        listWhiteList: addKeyToList(
          action?.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoadingGetListWhiteList: false,
      }
    case GET_LIST_ADD_WHITELIST_SUCCESS:
      return {
        ...state,
        listAddWhiteList: action?.payload?.items,
        isLoadingGetListAddWhiteList: false,
      }
    case CREATE_WHITELIST_SUCCESS:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case DELETE_WHITELIST_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      }
    //
    case GET_TONGLE_WHITELIST_FAILED:
    case CHANGE_TONGLE_WHITELIST_FAILED:
      return {
        ...state,
      }
    case GET_LIST_WHITELIST_FAILED:
      return {
        ...state,
        isLoadingGetListWhiteList: true,
      }
    case GET_LIST_ADD_WHITELIST_FAILED:
      return {
        ...state,
        isLoadingGetListAddWhiteList: true,
      }
    case CREATE_WHITELIST_FAILED:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case DELETE_WHITELIST_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }

    default:
      return state
  }
}
