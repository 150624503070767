import PropTypes from 'prop-types'
// import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Image, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  converSeed,
  converUserRankUser,
  getRankbyValue,
  MonsterTypeTournament,
  seed,
  SpecialHexType,
  StateSponsorTournament,
  TournamentType,
} from '~/common/constants'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { useQueryState } from '~/common/hooks/useQueryState'
import TournamentBracketsEditor from './chartRank'
import ChartRankCaro from './chartRankCaro'
import { saveAs } from 'file-saver'

function SponsorTournamentDetail(props) {
  // const { t } = useTranslation()
  const { isShowModal, onClose, sponsor } = props

  const { page, pageSize } = useQueryState()
  const {
    actions,
    data: { listUserByWallet },
  } = useUser()

  const [userWallet, setUserWallet] = useState([])
  const [sortListWallet, setSortListWallet] = useState([])

  const [listDetail, setListDetail] = useState('detail')

  useEffect(() => {
    const userResult = sponsor?.final_standings
      ? sponsor.final_standings.map((item) => item.name)
      : []
    if (sponsor.state === 2 || sponsor.state === 3) {
      setUserWallet(sponsor.register_list)
    } else {
      setUserWallet(userResult)
    }
  }, [sponsor])

  useEffect(() => {
    if (sponsor && userWallet && userWallet.length > 0) {
      const params = {
        allWallets: userWallet,
        pageSize: 100000,
        page,
      }
      actions.getUserByWallet(params)
    }
  }, [sponsor, userWallet, pageSize, page])

  useEffect(() => {
    // Sort listUserByWallet based on userWallet order
    const sortedList = [...listUserByWallet].sort((a, b) => {
      const indexA = userWallet.indexOf(a.address_wallet)
      const indexB = userWallet.indexOf(b.address_wallet)
      return indexA - indexB
    })

    const mergedList = sortedList?.map((user) => {
      // Find the corresponding final standing by matching user_name or any other field
      const standing = sponsor?.final_standings?.find(
        (item) => item.name === user.address_wallet,
      )
      return standing
        ? {
            ...user,
            scoreTournament: standing.score || -1,
            rankTournament: standing.rank || 0,
          }
        : user // If no matching standing is found, return the user as is
    })
    if (userWallet.length > 0) {
      setSortListWallet(
        mergedList.sort((a, b) => a.rankTournament - b.rankTournament),
      )
    } else {
      setSortListWallet([])
    }
  }, [listUserByWallet, userWallet, sponsor, listDetail])

  const handleClose = () => {
    onClose()
  }

  const uniquePlayers = new Set()

  sponsor?.match_list?.forEach((match) => {
    uniquePlayers.add(match.player_1)
    uniquePlayers.add(match.player_2)
  })

  const uniquePlayersArray = Array.from(uniquePlayers)

  const playerPositionMap = {}
  sponsor?.match_list?.forEach((match) => {
    playerPositionMap[match.player_1] = match.player_1_id
    playerPositionMap[match.player_2] = match.player_2_id
  })

  const sortedUniquePlayersArray = uniquePlayersArray
    ?.map((player) => [player, playerPositionMap[player]])
    ?.sort((a, b) => a[1] - b[1])
    ?.map((pair) => pair[0])
  const sortedListChartRank = [...listUserByWallet].sort((a, b) => {
    const indexA = sortedUniquePlayersArray.indexOf(a.address_wallet)
    const indexB = sortedUniquePlayersArray.indexOf(b.address_wallet)
    return indexA - indexB
  })
  const terrain_ban = [
    'Wilderness',
    'Water',
    'Vocalnose',
    'Plains',
    'Mountain',
    'Forest',
    'Desert',
  ]
  const handleExportFullCSV = () => {
    const sponsorBasicDetails = [
      ['Tournament Name', `"${sponsor?.tournament_name || 'N/A'}"`],
      ['Sponsor Name', `"${sponsor?.sponsor_name || 'N/A'}"`],
      ['Participants', `"${sponsor?.participants || 0}"`],
      ['Jump In', `"${sponsor?.jump_in ? 'Yes' : 'No'}"`],
      ['Use Allow List', `"${sponsor?.use_allow_list ? 'Yes' : 'No'}"`],
      ['Allow List Only', `"${sponsor?.allow_list_only ? 'Yes' : 'No'}"`],
      ['Monster Rank', `"${sponsor?.monster_rank || 'N/A'}"`],
      ['Monsters', `"${sponsor?.monsters || 'N/A'}"`],
      ['Turns', `"${sponsor?.turns || 'N/A'}"`],
      ['State', `"${sponsor?.state || 'N/A'}"`],
      ['Start Date', `"${sponsor?.date_string || 'N/A'}"`],
      ['End Time', `"${sponsor?.end_time || 'N/A'}"`],
      ['Creation Date', `"${sponsor?.crt_dt || 'N/A'}"`],
      ['Modified Date', `"${sponsor?.mod_dt || 'N/A'}"`],
      ['Created By', `"${sponsor?.crt_by || 'N/A'}"`],
      ['Modified By', `"${sponsor?.mod_by || 'N/A'}"`],
      ['Banner URL', `"${sponsor?.banner_url || 'N/A'}"`],
      ['Thumbnail URL', `"${sponsor?.thumbnail_url || 'N/A'}"`],
      ['Loading URL', `"${sponsor?.loading_url || 'N/A'}"`],
      ['Popup Banner URL', `"${sponsor?.banner_url_popup || 'N/A'}"`],
    ]

    const allowListDetails =
      sponsor?.allow_list?.map((address) => [`"${address}"`]) || []
    const entryListDetails =
      sponsor?.entry_list?.map((address) => [`"${address}"`]) || []
    const matchListDetails =
      sponsor?.match_list?.map((match) => [
        `"${match.match_id}"`,
        `"${match.round_id}"`,
        `"${match.player_1}"`,
        `"${match.player_2}"`,
        `"${match.state}"`,
        `"${match.winner}"`,
      ]) || []
    const finalStandingsDetails =
      sponsor?.final_standings?.map((standing) => [
        `"${standing.id}"`,
        `"${standing.name}"`,
        `"${standing.rank}"`,
        `"${standing.score}"`,
      ]) || []
    const playerDetails = sortListWallet?.map((player) => [
      `"${player.user_id}"`,
      `"${player.user_name || 'N/A'}"`,
      `"${player.address_wallet || 'N/A'}"`,
      `"${player.player_rank || 'N/A'}"`,
      `"${player.player_bio || 'N/A'}"`,
      `"${player.player_language || 'N/A'}"`,
      `"${player.bit_balance || 0}"`,
      `"${player.mapoint_amount || 0}"`,
      `"${player.phone_verified ? 'Yes' : 'No'}"`,
      `"${player.email_verified ? 'Yes' : 'No'}"`,
      `"${player.last_active || 'N/A'}"`,
      `"${player.last_login || 'N/A'}"`,
      `"${player.total_login_day || 0}"`,
      `"${player.login_day || 0}"`,
      `"${player.player_time || 0}"`,
      `"${player.role || 'N/A'}"`,
      `"${player.scoreTournament || 'N/A'}"`,
      `"${player.rankTournament || 'N/A'}"`,
      `"${player.avatar || 'N/A'}"`,
    ])

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        'Information Sponsor Tournament',
        sponsorBasicDetails.map((detail) => detail.join(',')).join('\n'),
        '',
        'Allow List',
        'Address',
        ...allowListDetails.map((detail) => detail.join(',')),
        '',
        'Entry List',
        'Address',
        ...entryListDetails.map((detail) => detail.join(',')),
        '',
        'Match List',
        'Match ID,Round ID,Player 1,Player 2,State,Winner',
        ...matchListDetails.map((match) => match.join(',')),
        '',
        'Final Standings',
        'Player ID,Name,Rank,Score',
        ...finalStandingsDetails.map((standing) => standing.join(',')),
        '',
        'Player Details',
        'User ID,User Name,Wallet Address,Player Rank,Player Bio,Language,Bit Balance,MaPoint Amount,Phone Verified,Email Verified,Last Active,Last Login,Total Login Days,Login Days,Player Time,Role,Score Tournament,Rank Tournament,Avatar URL',
        ...playerDetails.map((player) => player.join(',')),
      ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'full_sponsor_details.csv')
  }
  return (
    <Modal
      title={'Sponsored Tournament Detail'}
      onCancel={handleClose}
      open={isShowModal}
      width={1200}
      centered
      destroyOnClose
      footer={null}
    >
      <div>
        <Row>
          <Col span={9}>
            <div>
              {sponsor?.thumbnail_url !== '' &&
              sponsor?.thumbnail_url !== null ? (
                <div className="">
                  <Image
                    className="imageTournament"
                    style={{
                      width: '50vh',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                    src={sponsor?.thumbnail_url}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="text-[24px] mb-2 ml-3 ">
              {sponsor?.tournament_name}
            </div>
            <div
              style={{
                border: '1px solid #fff',
                padding: '8px',
                borderRadius: '8px',
              }}
            >
              <Row>
                <Col span={10} className="text-[12px]">
                  Date:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date(sponsor?.date * 1000).toLocaleString('en-US', {
                    month: 'short', // 'Feb'
                    day: '2-digit', // '20'
                    year: 'numeric', // '2024'
                    hour: '2-digit', // '12'
                    minute: '2-digit', // '00'
                    hour12: false, // 24-hour format
                    timeZone: 'UTC', // Show UTC
                    timeZoneName: 'short', // 'UTC'
                  })}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Registration:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date((sponsor?.date - 86400 - 60) * 1000).toLocaleString(
                    'en-US',
                    {
                      month: 'short', // 'Feb'
                      day: '2-digit', // '19' after subtracting 1 day
                      year: 'numeric', // '2024'
                      hour: '2-digit', // '12'
                      minute: '2-digit', // '00'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      timeZoneName: 'short', // 'UTC'
                    },
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Entry:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date((sponsor?.date - 1800) * 1000).toLocaleString(
                    'en-US',
                    {
                      month: 'short', // 'Feb'
                      day: '2-digit', // '20'
                      year: 'numeric', // '2024'
                      hour: '2-digit', // '11' if 30 minutes is subtracted from 12:00
                      minute: '2-digit', // '30'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      // timeZoneName: 'short', // 'UTC'
                    },
                  )}{' '}
                  ~{' '}
                  {new Date((sponsor?.date - 600) * 1000).toLocaleString(
                    'en-US',
                    {
                      hour: '2-digit', // '11' if 30 minutes is subtracted from 12:00
                      minute: '2-digit', // '30'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      timeZoneName: 'short', // 'UTC'
                    },
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Jump-in:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date((sponsor?.date - 600) * 1000).toLocaleString(
                    'en-US',
                    {
                      month: 'short', // 'Feb'
                      day: '2-digit', // '19' after subtracting 5 minutes
                      year: 'numeric', // '2024'
                      hour: '2-digit', // '12'
                      minute: '2-digit', // '00'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      // timeZoneName: 'short', // 'UTC'
                    },
                  )}{' '}
                  ~{' '}
                  {new Date((sponsor?.date - 60) * 1000).toLocaleString(
                    'en-US',
                    {
                      hour: '2-digit', // '12'
                      minute: '2-digit', // '00'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      timeZoneName: 'short', // 'UTC'
                    },
                  )}
                </Col>
              </Row>
            </div>
            <div className="mt-2" style={{ fontFamily: 'Rexliafree' }}>
              <div className="flex items-center">
                <div>Sponsor: {sponsor?.sponsor_name}</div>
                <div className="ml-[10%]">
                  ID: {sponsor?._id ? `...${sponsor._id.slice(-5)}` : ''}
                </div>
              </div>
              <div>Type: {TournamentType(sponsor?.type)}</div>
              <div># of participants: {sponsor?.participants}</div>
              <div># of registrations: {sponsor?.register_list?.length}</div>
              <div>
                AL:{' '}
                {!sponsor?.use_allow_list
                  ? 'None'
                  : sponsor?.allow_list_only
                  ? 'AlOnly'
                  : 'Priority'}
              </div>

              <div>
                Jump-in:{' '}
                {sponsor?.allow_list_only
                  ? 'Not Allowed'
                  : sponsor?.jump_in
                  ? 'Allowed'
                  : 'Not Allowed'}
              </div>
              <div className="flex items-center">
                Prize:
                {sponsor?.prize?.map((item, index) => (
                  <div className="flex items-center ml-1">
                    #{index + 1}
                    <img
                      className="w-[25px] h-[25px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                      alt=""
                    />
                    {item}
                    {sponsor.prize.length > 2 &&
                      index < sponsor.prize.length - 1 &&
                      ','}
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col span={1}>
            <div
              style={{
                borderLeft: '1px solid #fff',
                height: '100%',
                marginLeft: '50%',
              }}
            ></div>
          </Col>
          {listDetail === 'detail' && (
            <Col span={14}>
              <div>
                <Row gutter={18}>
                  <Col span={12}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-[16px]">
                          Rules
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Rank</Col>
                        <Col span={10}>
                          {getRankbyValue(sponsor?.monster_rank)}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Monster</Col>
                        <Col span={10}>{sponsor?.monsters}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Turns</Col>
                        <Col span={10}>{sponsor?.turns}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Special Hex</Col>
                        <Col span={10}>
                          {SpecialHexType(sponsor?.special_hex)}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Memory</Col>
                        <Col span={10}>
                          {sponsor?.memory ? 'Allowed' : 'Not Allowed'}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-[16px]">
                          Player Requirements
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>SMS Verification</Col>
                        <Col span={12}>{sponsor?.sms ? 'Required' : 'N/A'}</Col>
                      </Row>
                      <Row>
                        <Col span={12}>Player Rank</Col>
                        <Col span={12}>
                          {converUserRankUser(sponsor?.player_rank)}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>Cycle Score</Col>
                        <Col span={12}>{sponsor?.cycle_score_min} ~ </Col>
                      </Row>
                      <Row>
                        <Col span={12}>Battles</Col>
                        <Col span={12}>{sponsor?.battles_min} ~</Col>
                      </Row>
                      <Row>
                        <Col span={12}>Playing Days</Col>
                        <Col span={12}>{sponsor?.playing_days_min} ~</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row gutter={18} className="mt-4">
                  <Col span={24}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <div className="text-[16px]">Monster Requirements</div>
                      <Row>
                        <Col span={6}>Cycle</Col>
                        <Col span={18}>
                          {!sponsor?.cycle || sponsor.cycle.length === 0
                            ? 'All'
                            : sponsor?.cycle?.map((t) => `${t}, `).join('')}
                        </Col>
                      </Row>

                      <Row>
                        <Col span={6}>Type</Col>
                        <Col span={18}>
                          {MonsterTypeTournament(sponsor?.monster_type)}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>Age</Col>
                        <Col span={18}>
                          {sponsor?.age_min === 0 ? '' : sponsor?.age_min} ~{' '}
                          {sponsor?.age_max === 0 ? '' : sponsor?.age_max}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>Terrain</Col>
                        <Col span={18} className="flex items-center">
                          {terrain_ban
                            ?.filter(
                              (item) => !sponsor?.terrain_ban?.includes(item),
                            )
                            ?.map((item, index) => (
                              <img
                                key={index}
                                className="w-[20px] h-[20px] mr-1"
                                src={`${process.env.PUBLIC_URL}/svgs/tournament/terrain/${item}.svg`}
                                alt=""
                              />
                            ))}
                        </Col>
                      </Row>

                      <div>Main Seed</div>
                      <div className="flex flex-wrap items-center">
                        {seed
                          ?.filter(
                            (item) => !sponsor?.main_seed_ban?.includes(item),
                          )
                          ?.map((item, index) => (
                            <img
                              key={index}
                              className="w-[40px] h-[40px] mt-1"
                              src={`${
                                process.env.PUBLIC_URL
                              }/svgs/tournament/mainseed/${converSeed(
                                item,
                              )}.svg`}
                              alt=""
                            />
                          ))}
                      </div>

                      <div>Sub Seed</div>
                      <div className="flex flex-wrap items-center">
                        {seed
                          ?.filter(
                            (item) => !sponsor?.sub_seed_ban?.includes(item),
                          )
                          ?.map((item, index) => (
                            <img
                              key={index}
                              className="w-[40px] h-[40px] mt-1"
                              src={`${
                                process.env.PUBLIC_URL
                              }/svgs/tournament/mainseed/${converSeed(
                                item,
                              )}.svg`}
                              alt=""
                            />
                          ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
          {listDetail === 'listWatch' && (
            <Col
              span={14}
              style={{
                fontFamily: 'Rexliafree',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {sponsor.state === 5 && (
                <>
                  {sponsor.type === 1 && (
                    <ChartRankCaro
                      sortedListChartRank={sortedListChartRank}
                      participantsMatch_list={sponsor?.match_list}
                    />
                  )}
                  {sponsor.type === 0 && (
                    <TournamentBracketsEditor
                      sponsor={sponsor}
                      participants={sortedUniquePlayersArray}
                      participantsMatch_list={sponsor?.match_list}
                    />
                  )}
                </>
              )}

              {sponsor.state === 4 &&
                sortListWallet?.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[480px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}` ?? '../svgs/camera.svg'}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name}
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.scoreTournament}
                      </div>
                    </div>
                  </div>
                ))}
              {(sponsor.state === 2 || sponsor.state === 3) &&
                sortListWallet?.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[480px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}` ?? '../svgs/camera.svg'}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Col>
          )}
          {listDetail === 'listResult' && (
            <Col
              span={14}
              style={{
                fontFamily: 'Rexliafree',
              }}
            >
              <div className="flex items-center justify-center text-[24px]">
                {sponsor.state === 2 || sponsor.state === 3
                  ? `Waiting for Entry (${sponsor.register_list?.length}/${sponsor.participants})`
                  : 'Congratulations!!!'}
              </div>
              {sponsor.state === 5 && (
                <div className="overflow-y-auto max-h-[420px] gameMenu">
                  {sortListWallet?.map((result, index) => (
                    <div key={index} className={index === 0 ? 'mt-4' : ''}>
                      <div className="flex justify-center text-[20px] h-[50px]">
                        <div
                          className="flex items-center w-[480px] h-full justify-start p-4"
                          style={
                            index !== 0
                              ? {
                                  background: 'rgba(24, 45, 86, 1)',
                                  color: 'rgba(255, 255, 255, 1)',
                                  borderRight:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  borderLeft:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  borderBottom:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  position: 'relative',
                                }
                              : {
                                  background: 'rgba(24, 45, 86, 1)',
                                  color: 'rgba(255, 255, 255, 1)',
                                  border: '2px solid rgba(136, 136, 136, 1)',
                                  position: 'relative',
                                }
                          }
                        >
                          <img
                            className="absolute left-[-35px] top-[3px]"
                            src={`${process.env.PUBLIC_URL}/svgs/guild/level/${result.rankTournament}.svg`}
                            alt=""
                            style={{
                              zIndex: 1,
                              width:
                                result.rankTournament === 1 ? '45px' : '40px',
                              height:
                                result.rankTournament === 1 ? '45px' : '40px',
                            }}
                          />
                          {result.avatar ? (
                            <>
                              <img
                                className="w-[35px] h-[35px] mr-4 rounded-full"
                                // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                                src={`${result.avatar}`}
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <img
                                className="w-[35px] h-[35px] mr-4 rounded-full"
                                // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                                src={`../svgs/camera.svg`}
                                alt=""
                              />
                            </>
                          )}

                          <div
                            className="mr-4"
                            style={{
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {result.user_name} (
                            {converUserRankUser(result?.player_rank)})
                            <div className="text-[12px]">
                              {result?.address_wallet}
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex items-center justify-center"
                          style={
                            index !== 0
                              ? {
                                  borderRight:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  borderBottom:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  background: 'rgba(255, 255, 255, 1)',
                                  color: 'rgba(0, 0, 0, 1)',
                                  width: '50px',
                                  height: '50px',
                                }
                              : {
                                  borderRight:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  borderTop: '2px solid rgba(136, 136, 136, 1)',
                                  borderBottom:
                                    '2px solid rgba(136, 136, 136, 1)',
                                  background: 'rgba(255, 255, 255, 1)',
                                  color: 'rgba(0, 0, 0, 1)',
                                  width: '50px',
                                  height: '50px',
                                }
                          }
                        >
                          {result.scoreTournament}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {sponsor.state === 4 &&
                sortListWallet?.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[480px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}` ?? '../svgs/camera.svg'}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name} (
                          {converUserRankUser(result?.player_rank)})
                          <div className="text-[12px]">
                            {result?.address_wallet}
                          </div>
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.scoreTournament}
                      </div>
                    </div>
                  </div>
                ))}
              {(sponsor.state === 2 || sponsor.state === 3) &&
                sortListWallet?.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[480px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}` ?? '../svgs/camera.svg'}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name} (
                          {converUserRankUser(result?.player_rank)})
                          <div className="text-[12px]">
                            {result?.address_wallet}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Col>
          )}
        </Row>
        <div>
          {listDetail === 'detail' &&
            (sponsor?.state === 1 || sponsor?.state === 0 ? (
              <Button
                style={{
                  width: '20%',
                  marginTop: '20px',
                  marginLeft: '61%',
                  background: 'rgba(24, 45, 86, 1)',
                  border: '2px solid rgba(12, 200, 254, 1)',
                  pointerEvents: 'none',
                }}
              >
                {StateSponsorTournament(sponsor?.state)}
              </Button>
            ) : (
              <div style={{ display: 'flex', gap: '10px' }}>
                {(sponsor?.state === 4 || sponsor?.state === 5) && (
                  <Button
                    style={{
                      width: '20%',
                      marginTop: '20px',
                      marginLeft: '53%',
                      background: 'rgba(74, 175, 79, 1)',
                      border: '2px solid rgba(74, 175, 79, 1)',
                    }}
                    onClick={() => setListDetail('listWatch')}
                  >
                    Watch
                  </Button>
                )}

                <Button
                  style={{
                    width: '20%',
                    marginTop: '20px',
                    marginLeft:
                      sponsor?.state === 4 || sponsor?.state === 5
                        ? '0'
                        : '60%',
                    background: 'rgba(74, 175, 79, 1)',
                    border: '2px solid rgba(74, 175, 79, 1)',
                  }}
                  onClick={() => setListDetail('listResult')}
                >
                  Result
                </Button>
              </div>
            ))}
          {listDetail === 'listWatch' && (
            <Button
              style={{
                width: '20%',
                marginTop: '20px',
                marginLeft: '61%',
                background: 'rgba(255, 0, 0, 1)',
                border: '2px solid rgba(255, 0, 0, 1)',
              }}
              onClick={() => setListDetail('detail')}
            >
              Exit
            </Button>
          )}
          {listDetail === 'listResult' && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                style={{
                  width: '20%',
                  marginTop: '20px',
                  marginLeft: '60%',
                  background: 'rgba(255, 0, 0, 1)',
                  border: '2px solid rgba(255, 0, 0, 1)',
                }}
                onClick={() => setListDetail('detail')}
              >
                Exit
              </Button>
              <Button
                onClick={handleExportFullCSV}
                style={{
                  width: '10%',
                  marginTop: '20px',
                  marginLeft: '10%',
                  background: 'rgba(255, 0, 0, 1)',
                  border: '2px solid rgba(255, 0, 0, 1)',
                }}
              >
                export CSV
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

SponsorTournamentDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SponsorTournamentDetail
