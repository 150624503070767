import {
  GET_DEPOSIT_POOLS_START,
  GET_DEPOSIT_POOLS_SUCCESS,
  GET_DEPOSIT_POOLS_FAILED,
  GET_WITHDRAW_POOLS_START,
  GET_WITHDRAW_POOLS_SUCCESS,
  GET_WITHDRAW_POOLS_FAILED,
  UPDATE_DEPOSIT_POOLS_START,
  UPDATE_DEPOSIT_POOLS_SUCCESS,
  UPDATE_DEPOSIT_POOLS_FAILED,
  UPDATE_WITHDRAW_POOLS_START,
  UPDATE_WITHDRAW_POOLS_SUCCESS,
  UPDATE_WITHDRAW_POOLS_FAILED,
  GET_LIST_POOL_START,
  GET_LIST_POOL_SUCCESS,
  GET_LIST_POOL_FAILED,
  CREATE_POOL_START,
  CREATE_POOL_SUCCESS,
  CREATE_POOL_FAILED,
  CLOSE_POOL_START,
  CLOSE_POOL_SUCCESS,
  CLOSE_POOL_FAILED,
  EDIT_POOL_START,
  EDIT_POOL_SUCCESS,
  EDIT_POOL_FAILED,
  DELETE_API_GAME_MONSTER_NFT_START,
  DELETE_API_GAME_MONSTER_NFT_SUCCESS,
  DELETE_API_GAME_MONSTER_NFT_FAILED,
  DELETE_API_MARKET_MONSTER_NFT_START,
  DELETE_API_MARKET_MONSTER_NFT_SUCCESS,
  DELETE_API_MARKET_MONSTER_NFT_FAILED,
} from '../actions/pools'

const initialState = {
  isLoadingDeposit: false,
  isLoadingWithdraw: false,
  isLoadingListPool: false,
  isLoadingCreate: false,
  isLoadingEdit: false,
  isLoadingClose: false,
  listPool: [],
  deposit: 0,
  withdraw: 0,
  isLoading: false,
  dataprocessId: null,
}

/**
 * Pools reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function poolsManagement(state = initialState, action) {
  switch (action.type) {
    case CLOSE_POOL_START:
      return {
        ...state,
        isLoadingClose: true,
      }
    case EDIT_POOL_START:
      return {
        ...state,
        isLoadingEdit: true,
      }
    case CREATE_POOL_START:
      return {
        ...state,
        isLoadingCreate: true,
      }
    case GET_LIST_POOL_START:
      return {
        ...state,
        isLoadingListPool: true,
      }
    case GET_DEPOSIT_POOLS_START:
    case DELETE_API_GAME_MONSTER_NFT_START:
    case DELETE_API_MARKET_MONSTER_NFT_START:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_DEPOSIT_POOLS_START:
      return {
        ...state,
        isLoadingDeposit: true,
      }

    case GET_WITHDRAW_POOLS_START:
    case UPDATE_WITHDRAW_POOLS_START:
      return {
        ...state,
        isLoadingWithdraw: true,
      }
    //
    case GET_LIST_POOL_SUCCESS:
      return {
        ...state,
        listPool: action?.payload?.listPools,
        isLoadingListPool: false,
      }
    case GET_DEPOSIT_POOLS_SUCCESS:
      return {
        ...state,
        deposit: action?.payload?.txid?.ranking_pools,
        withdraw: action?.payload?.txid?.hashchip_pools,
        isLoading: false,
      }
    case UPDATE_DEPOSIT_POOLS_SUCCESS:
      return {
        ...state,
        isLoadingDeposit: false,
      }
    case GET_WITHDRAW_POOLS_SUCCESS:
      return {
        ...state,
        withdraw: 0,
        isLoadingWithdraw: false,
      }
    case UPDATE_WITHDRAW_POOLS_SUCCESS:
      return {
        ...state,
        isLoadingWithdraw: false,
      }
    case CREATE_POOL_SUCCESS:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case EDIT_POOL_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
      }
    case DELETE_API_GAME_MONSTER_NFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_API_MARKET_MONSTER_NFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataprocessId: action?.payload,
      }
    case EDIT_POOL_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
      }
    //
    case GET_LIST_POOL_FAILED:
      return {
        ...state,
        isLoadingListPool: true,
      }
    case GET_DEPOSIT_POOLS_FAILED:
    case DELETE_API_GAME_MONSTER_NFT_FAILED:
    case DELETE_API_MARKET_MONSTER_NFT_FAILED:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_DEPOSIT_POOLS_FAILED:
      return {
        ...state,
        isLoadingDeposit: false,
      }

    case GET_WITHDRAW_POOLS_FAILED:
    case UPDATE_WITHDRAW_POOLS_FAILED:
      return {
        ...state,
        isLoadingDeposit: false,
      }

    case CREATE_POOL_FAILED:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case EDIT_POOL_FAILED:
      return {
        ...state,
        isLoadingEdit: false,
      }
    case CLOSE_POOL_FAILED:
      return {
        ...state,
        isLoadingClose: false,
      }
    default:
      return state
  }
}
