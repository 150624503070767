import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

const RankingGuild = ({
  dataCycleGuildRank,
  cyclePool,
  totalGuild,
  goToPrevious,
  goToNext,
  currentCycleIndex,
  sortedDataListPool,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  useEffect(() => {
    const now = Date.now() / 1000

    const matchedCycleIndex = dataCycleGuildRank.findIndex((cycle) => {
      const fromDateCycle = Number(cycle.from_date_cycle)
      const toDateCycle = Number(cycle.to_date_cycle)
      return now >= fromDateCycle && now <= toDateCycle
    })

    if (matchedCycleIndex !== -1) {
      setCurrentIndex(matchedCycleIndex)
    }
  }, [dataCycleGuildRank])

  const currentData = dataCycleGuildRank[currentIndex]

  const totalScore = (ranked_score, exploration_score) => {
    return Number(ranked_score) + Number(exploration_score)
  }

  function formatTimestampToMonthYear(timestamp) {
    const date = new Date(timestamp * 1000)
    const options = { month: 'short', year: 'numeric' }
    return date.toLocaleDateString('en-US', options).replace(' ', '. ')
  }

  function getNextTwoMonthsWithCurrentCheck(timestamp, monthCycleData) {
    const startDate = new Date(timestamp * 1000)
    const months = []

    for (let i = 0; i < 3; i++) {
      const currentMonthData = monthCycleData.find((monthData) => {
        const fromDate = Number(monthData.from_date)
        const toDate = Number(monthData.to_date)
        const monthStart = startDate.getTime() / 1000
        return monthStart >= fromDate && monthStart <= toDate
      })

      const formattedDate = formatTimestampToMonthYear(
        startDate.getTime() / 1000,
      )
      const isCurrentMonth = isMonthCurrent(startDate)

      months.push({
        month: formattedDate,
        isCurrentMonth,
        data: currentMonthData || null,
      })

      startDate.setMonth(startDate.getMonth() + 1)
    }

    return months
  }

  function isMonthCurrent(date) {
    const now = new Date()
    return (
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    )
  }
  const nextTwoMonths = getNextTwoMonthsWithCurrentCheck(
    cyclePool?.timeStart,
    currentData?.dataMonthCycle || [],
  )

  return (
    <>
      <Row
        className="mt-[15px] p-[12px]"
        justify="space-between"
        style={{
          fontFamily: 'Rexliafree',
          display: 'flex',
          justifyContent: 'center',
          gap: '6%',
        }}
      >
        <Col
          span={7}
          style={{
            border: '1px solid #E5E5E5',
            borderRadius: 8,
            background:
              nextTwoMonths[0].isCurrentMonth === true
                ? 'rgb(12, 200, 254)'
                : '#004558',
            padding: '0',
          }}
        >
          <div>
            <Row style={{ padding: '0 12px' }}>
              <Col span={12} className="text-[20px]">
                {nextTwoMonths[0].month}
              </Col>
              <Col
                span={12}
                className="flex items-center text-[20px] justify-end"
              >
                <img
                  className="w-[35px] h-[35px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                  alt=""
                />
                <div>{nextTwoMonths[0]?.data?.oas_earned ?? 0}</div>
              </Col>
            </Row>
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <Row>
                <Col span={13} className="text-[16px]">
                  Rank
                </Col>
                <Col span={11} className="text-[16px] flex justify-end">
                  {nextTwoMonths[0]?.data?.guild_placement &&
                  nextTwoMonths[0]?.data?.total_placement
                    ? `${nextTwoMonths[0]?.data?.guild_placement}/${totalGuild}`
                    : '-/-'}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="text-[16px]">
                  Total Score
                </Col>
                <Col span={11} className="text-[16px] flex justify-end">
                  {totalScore(
                    nextTwoMonths[0]?.data?.ranked_score ?? 0,
                    nextTwoMonths[0]?.data?.exploration_score ?? 0,
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="flex items-center">
                  <img
                    className="w-[12px] h-[12px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                    alt=""
                  />
                  <div className="text-[12px]">Ranked Match</div>
                </Col>
                <Col
                  span={11}
                  className="flex items-center justify-end text-[12px]"
                >
                  {nextTwoMonths[0]?.data?.ranked_score ?? 0}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="flex items-center">
                  <img
                    className="w-[12px] h-[12px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                    alt=""
                  />
                  <div className="text-[12px]">Exploration</div>
                </Col>
                <Col
                  span={11}
                  className="flex items-center justify-end text-[12px]"
                >
                  {nextTwoMonths[0]?.data?.exploration_score ?? 0}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            border: '1px solid #E5E5E5',
            borderRadius: 8,
            background:
              nextTwoMonths[1].isCurrentMonth === true
                ? 'rgb(12, 200, 254)'
                : '#004558',
            padding: '0',
          }}
        >
          <div>
            <Row style={{ padding: '0 12px' }}>
              <Col span={12} className="text-[20px]">
                {nextTwoMonths[1].month}
              </Col>
              <Col
                span={12}
                className="flex items-center text-[20px] justify-end"
              >
                <img
                  className="w-[35px] h-[35px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                  alt=""
                />
                <div>{nextTwoMonths[1]?.data?.oas_earned ?? 0}</div>
              </Col>
            </Row>
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <Row>
                <Col span={13} className="text-[16px]">
                  Rank
                </Col>
                <Col span={11} className="text-[16px] flex justify-end">
                  {nextTwoMonths[1]?.data?.guild_placement &&
                  nextTwoMonths[1]?.data?.total_placement
                    ? `${nextTwoMonths[1]?.data?.guild_placement}/${totalGuild}`
                    : '-/-'}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="text-[16px]">
                  Total Score
                </Col>
                <Col span={11} className="text-[16px] flex justify-end">
                  {totalScore(
                    nextTwoMonths[1]?.data?.ranked_score ?? 0,
                    nextTwoMonths[1]?.data?.exploration_score ?? 0,
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="flex items-center">
                  <img
                    className="w-[12px] h-[12px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                    alt=""
                  />
                  <div className="text-[12px]">Ranked Match</div>
                </Col>
                <Col
                  span={11}
                  className="flex items-center justify-end text-[12px]"
                >
                  {nextTwoMonths[1]?.data?.ranked_score ?? 0}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="flex items-center">
                  <img
                    className="w-[12px] h-[12px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                    alt=""
                  />
                  <div className="text-[12px]">Exploration</div>
                </Col>
                <Col
                  span={11}
                  className="flex items-center justify-end text-[12px]"
                >
                  {nextTwoMonths[1]?.data?.exploration_score ?? 0}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            border: '1px solid #E5E5E5',
            borderRadius: 8,
            background:
              nextTwoMonths[2].isCurrentMonth === true
                ? 'rgb(12, 200, 254)'
                : '#004558',
            padding: '0',
          }}
        >
          <div>
            <Row style={{ padding: '0 12px' }}>
              <Col span={12} className="text-[20px]">
                {nextTwoMonths[2].month}
              </Col>
              <Col
                span={12}
                className="flex items-center text-[20px] justify-end"
              >
                <img
                  className="w-[35px] h-[35px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                  alt=""
                />
                <div>{nextTwoMonths[2]?.data?.oas_earned ?? 0}</div>
              </Col>
            </Row>
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <Row>
                <Col span={13} className="text-[16px]">
                  Rank
                </Col>
                <Col span={11} className="text-[16px] flex justify-end">
                  {nextTwoMonths[2]?.data?.guild_placement &&
                  nextTwoMonths[2]?.data?.total_placement
                    ? `${nextTwoMonths[2]?.data?.guild_placement}/${totalGuild}`
                    : '-/-'}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="text-[16px]">
                  Total Score
                </Col>
                <Col span={11} className="text-[16px] flex justify-end">
                  {totalScore(
                    nextTwoMonths[2]?.data?.ranked_score ?? 0,
                    nextTwoMonths[2]?.data?.exploration_score ?? 0,
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="flex items-center">
                  <img
                    className="w-[12px] h-[12px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                    alt=""
                  />
                  <div className="text-[12px]">Ranked Match</div>
                </Col>
                <Col
                  span={11}
                  className="flex items-center justify-end text-[12px]"
                >
                  {nextTwoMonths[2]?.data?.ranked_score ?? 0}
                </Col>
              </Row>
              <Row>
                <Col span={13} className="flex items-center">
                  <img
                    className="w-[12px] h-[12px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                    alt=""
                  />
                  <div className="text-[12px]">Exploration</div>
                </Col>
                <Col
                  span={11}
                  className="flex items-center justify-end text-[12px]"
                >
                  {nextTwoMonths[2]?.data?.exploration_score ?? 0}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        gutter={24}
        align="middle"
        className="mt-[15px]"
        style={{ fontFamily: 'Rexliafree' }}
      >
        <Col span={24} className="flex items-center justify-center">
          <CaretLeftOutlined
            onClick={goToPrevious}
            disabled={currentCycleIndex === 0}
            style={{
              color: currentCycleIndex === 0 ? '#004a5c' : '#00c8fa',
              fontSize: '40px',
              cursor: currentCycleIndex === 0 ? 'not-allowed' : 'pointer',
            }}
          />
          <div className="text-[20px] ">{cyclePool?.name}</div>
          <div className="text-[12px] ml-2">
            {'('}
            {`${new Date(cyclePool?.timeStart * 1000).toLocaleDateString(
              'en-US',
              {
                month: 'short',
                timeZone: 'UTC',
              },
            )}.${new Date(cyclePool?.timeStart * 1000).getUTCFullYear()}`}{' '}
            -{' '}
            {`${new Date(cyclePool?.timeEnd * 1000).toLocaleDateString(
              'en-US',
              {
                month: 'short',
                timeZone: 'UTC',
              },
            )}.${new Date(cyclePool?.timeEnd * 1000).getUTCFullYear()}`}
            {')'}
          </div>
          <CaretRightOutlined
            onClick={goToNext}
            disabled={currentCycleIndex === sortedDataListPool - 1}
            style={{
              color:
                currentCycleIndex === sortedDataListPool - 1
                  ? '#004a5c'
                  : '#00c8fa',
              fontSize: '40px',
              cursor:
                currentCycleIndex === sortedDataListPool - 1
                  ? 'not-allowed'
                  : 'pointer',
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default RankingGuild
