import PropTypes from 'prop-types'
import { Col, Image, Modal, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import './index.css'
import {
  converImageCollection,
  convertImageFamrOfMonster,
  convertImageMonsterSeed,
  convertSeed,
  extractConvertMonsterSeed,
  getRankOverall,
} from '~/common/constants'
import { useUser } from '~/views/app/redux/hooks/useUser'
function ModalDatabase(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, address, totalSeed } = props
  const monsterRefs = useRef({})

  const scrollToMonster = (monsterId) => {
    const monsterElement = monsterRefs.current[monsterId]

    if (monsterElement) {
      monsterElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  const handleClose = () => {
    onClose()
  }
  const seed = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  ]
  const dataBaseMonsterSeed = [
    {
      id: 1,
      name: 'Faireaf',
      dataBase: [
        'Faireaf_Ame_chan_1',
        'Faireaf_Faireaf_1',
        'Faireaf_Layla_1',
        'Faireaf_P1_1',
        'Faireaf_SignWitch_1',
        'Faireaf_Tofu_1',
        'Faireaf_Valius_1',
      ],
    },
    {
      id: 2,
      name: 'Golem',
      dataBase: [
        'Golem_DEKAUSA_1',
        'Golem_Golem_1',
        'Golem_Kids_Hero_1',
        'Golem_Kids_Villain_1',
        'Golem_Ossan_Paradise_1',
        'Golem_Planetman_1',
        'Golem_UPA_1',
      ],
    },
    {
      id: 3,
      name: 'Tofu',
      dataBase: [
        'Tofu_DEKAUSA_1',
        'Tofu_Golem_1',
        'Tofu_Icelime_1',
        'Tofu_Planetman_1',
        'Tofu_SignWitch_1',
        'Tofu_Tofu_1',
        'Tofu_Yamato_1',
      ],
    },
    {
      id: 4,
      name: 'Unicorn',
      dataBase: [
        'Unicorn_AstarCats_1',
        'Unicorn_CCT_1',
        'Unicorn_ODENPETS_1',
        'Unicorn_Planetman_1',
        'Unicorn_Unicorn_1',
        'Unicorn_UPA_1',
        'Unicorn_Valius_1',
      ],
    },
    {
      id: 5,
      name: 'Valius',
      dataBase: [
        'Valius_CCT_1',
        'Valius_DEKAUSA_1',
        'Valius_Kids_Villain_1',
        'Valius_ODENPETS_1',
        'Valius_Ossan_Paradise_1',
        'Valius_Unicorn_1',
        'Valius_Valius_1',
      ],
    },
    {
      id: 6,
      name: 'Astar Cats',
      dataBase: [
        'AstarCats_AstarCats_1',
        'AstarCats_Faireaf_1',
        'AstarCats_Hiyori_1',
        'AstarCats_Kids_Villain_1',
        'AstarCats_Ossan_Paradise_1',
        'AstarCats_SignWitch_1',
        'AstarCats_Tofu_1',
      ],
    },
    {
      id: 7,
      name: 'Sign Witch',
      dataBase: [
        'SignWitch_Ame_chan_1',
        'SignWitch_AstarCats_1',
        'SignWitch_AstarCats_2',
        'SignWitch_Faireaf_1',
        'SignWitch_Hiyori_1',
        'SignWitch_Layla_1',
        'SignWitch_P1_1',
        'SignWitch_Planetman_1',
        'SignWitch_SignWitch_1',
        'SignWitch_Tofu_1',
        'SignWitch_Unicorn_1',
        'SignWitch_UPA_1',
        'SignWitch_Valius_1',
      ],
    },
    {
      id: 8,
      name: 'Layla',
      dataBase: [
        'Layla_Faireaf_1',
        'Layla_Hiyori_1',
        'Layla_Icelime_1',
        'Layla_Layla_1',
        'Layla_P1_1',
        'Layla_SignWitch_1',
        'Layla_Valius_1',
      ],
    },
    {
      id: 9,
      name: 'ODENPETS',
      dataBase: [
        'ODENPETS_Ame_chan_1',
        'ODENPETS_DEKAUSA_1',
        'ODENPETS_Kids_Hero_1',
        'ODENPETS_ODENPETS_1',
        'ODENPETS_P1_1',
      ],
    },
    {
      id: 10,
      name: 'UPA',
      dataBase: [
        'UPA_AstarCats_1',
        'UPA_Golem_1',
        'UPA_Kids_Villain_1',
        'UPA_ODENPETS_1',
        'UPA_Ossan_Paradise_1',
        'UPA_UPA_1',
        'UPA_Yamato_1',
      ],
    },
    {
      id: 11,
      name: 'Hiyori',
      dataBase: [
        'Hiyori_Ame_chan_1',
        'Hiyori_Faireaf_1',
        'Hiyori_Hiyori_1',
        'Hiyori_Icelime_1',
        'Hiyori_Layla_1',
        'Hiyori_Unicorn_1',
        'Hiyori_Valius_1',
      ],
    },
    {
      id: 12,
      name: 'Yamato',
      dataBase: [
        'Yamato_Ame_chan_1',
        'Yamato_AstarCats_1',
        'Yamato_ODENPETS_1',
        'Yamato_P1_1',
        'Yamato_Tofu_1',
        'Yamato_UPA_1',
        'Yamato_Yamato_1',
      ],
    },
    {
      id: 13,
      name: 'DEKAUSA',
      dataBase: [
        'DEKAUSA_CCT_1',
        'DEKAUSA_DEKAUSA_1',
        'DEKAUSA_Golem_1',
        'DEKAUSA_ODENPETS_1',
        'DEKAUSA_Planetman_1',
        'DEKAUSA_Tofu_1',
        'DEKAUSA_Yamato_1',
      ],
    },
    {
      id: 14,
      name: 'Candy Cony Town',
      dataBase: ['CCT_CCT_1', 'CCT_CCT_2', 'CCT_CCT_3', 'CCT_CCT_4'],
    },
    {
      id: 15,
      name: 'Icelime',
      dataBase: [
        'Icelime_CCT_1',
        'Icelime_DEKAUSA_1',
        'Icelime_Icelime_1',
        'Icelime_Kids_Hero_1',
        'Icelime_Unicorn_1',
        'Icelime_UPA_1',
        'Icelime_Yamato_1',
      ],
    },
    {
      id: 16,
      name: 'Ame-chan',
      dataBase: [
        'Ame_chan_Ame_chan_1',
        'Ame_chan_Golem_1',
        'Ame_chan_Hiyori_1',
        'Ame_chan_Icelime_1',
        'Ame_chan_Kids_Villain_1',
        'Ame_chan_Layla_1',
        'Ame_chan_Yamato_1',
      ],
    },
    {
      id: 17,
      name: 'Kids Hero',
      dataBase: [
        'Kids_Hero_Kids_Hero_1',
        'Kids_Hero_Kids_Hero_2',
        'Kids_Hero_Kids_Hero_3',
        'Kids_Hero_Kids_Hero_4',
        'Kids_Hero_Kids_Hero_5',
      ],
    },
    {
      id: 18,
      name: 'Kids Villain',
      dataBase: [
        'Kids_Villain_Kids_Villain_1',
        'Kids_Villain_Kids_Villain_2',
        'Kids_Villain_Kids_Villain_3',
        'Kids_Villain_Kids_Villain_4',
        'Kids_Villain_Kids_Villain_5',
      ],
    },
    {
      id: 19,
      name: 'Sake Barral PlanetMan',
      dataBase: [
        'Planetman_AstarCats_1',
        'Planetman_CCT_1',
        'Planetman_Golem_1',
        'Planetman_Kids_Hero_1',
        'Planetman_Ossan_Paradise_1',
        'Planetman_Planetman_1',
        'Planetman_Unicorn_1',
      ],
    },
    {
      id: 20,
      name: 'P1',
      dataBase: [
        'P1_Faireaf_1',
        'P1_Hiyori_1',
        'P1_Icelime_1',
        'P1_Kids_Hero_1',
        'P1_Layla_1',
        'P1_P1_1',
        'P1_SignWitch_1',
      ],
    },
    {
      id: 21,
      name: 'Ossan Paradise',
      dataBase: [
        'Ossan_Paradise_Ossan_Paradise_1',
        'Ossan_Paradise_Ossan_Paradise_2',
        'Ossan_Paradise_Ossan_Paradise_3',
        'Ossan_Paradise_Ossan_Paradise_4',
        'Ossan_Paradise_Ossan_Paradise_5',
      ],
    },
  ]
  const {
    actions,
    data: { listMonsterAddressWallet },
  } = useUser()
  useEffect(() => {
    actions.getListMonsterAddressWallet(address)
  }, [address])

  const [dataMonster, setDataMonster] = useState([])
  const [obtaining, setObtaining] = useState(0)
  const [raising, setRaising] = useState(0)

  useEffect(() => {
    if (listMonsterAddressWallet && listMonsterAddressWallet.length > 0) {
      const firstMonsterScriptId = listMonsterAddressWallet[0].monster_script_id

      const matchingMonsters = listMonsterAddressWallet.filter(
        (item) => item.monster_script_id === firstMonsterScriptId,
      )

      setObtaining(matchingMonsters.length)
      const totalRaising = matchingMonsters.reduce(
        (sum, item) => sum + item.training_count,
        0,
      )
      setRaising(totalRaising)

      const allOverallEqual = matchingMonsters.every(
        (item) => item.overall === matchingMonsters[0].overall,
      )

      let maxOverallMonsterFirst

      if (allOverallEqual) {
        maxOverallMonsterFirst = matchingMonsters.reduce((min, item) => {
          return item.monster_nft_id < (min?.monster_nft_id || Infinity)
            ? item
            : min
        }, null)
      } else {
        maxOverallMonsterFirst = matchingMonsters.reduce((max, item) => {
          if (item.overall > (max?.overall || 0)) {
            return item
          } else if (
            item.overall === max?.overall &&
            item.monster_nft_id < max.monster_nft_id
          ) {
            return item
          }
          return max
        }, null)
      }

      if (maxOverallMonsterFirst) {
        setDataMonster([maxOverallMonsterFirst])
      }
    }
  }, [listMonsterAddressWallet])

  const handleMonsterClick = (monsterScriptId) => {
    const filterMonsterScriptID = listMonsterAddressWallet.filter(
      (item) => item.monster_script_id === monsterScriptId,
    )
    setObtaining(filterMonsterScriptID.length)

    const allOverallEqual = filterMonsterScriptID.every(
      (item) => item.overall === filterMonsterScriptID[0].overall,
    )

    let maxOverallMonster

    if (allOverallEqual) {
      maxOverallMonster = filterMonsterScriptID.reduce((min, item) => {
        return item.monster_nft_id < (min?.monster_nft_id || Infinity)
          ? item
          : min
      }, null)
    } else {
      maxOverallMonster = filterMonsterScriptID.reduce((max, item) => {
        if (item.overall > (max?.overall || 0)) {
          return item
        } else if (
          item.overall === max?.overall &&
          item.monster_nft_id < max.monster_nft_id
        ) {
          return item
        }
        return max
      }, null)
    }

    if (maxOverallMonster) {
      setDataMonster([maxOverallMonster])
    }
  }

  const monsterSeedWithActiveCount = dataBaseMonsterSeed.map((item) => {
    const activeCount = item.dataBase.reduce(
      (count, monsterItem) =>
        listMonsterAddressWallet?.some(
          (dataItem) => dataItem.monster_script_id === monsterItem,
        )
          ? count + 1
          : count,
      0,
    )
    return { ...item, activeCount }
  })

  return (
    <Modal
      title={<>{t('managementUsers.form.ModalDatabase')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={1250}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="font-normal">
        <Row gutter={24}>
          <Col span={10}>
            <div className="relative card__detail w-[93.2%] h-[300px] md:w-[93.2%] md:h-[656px] flex items-center justify-center">
              <div className="relative flex items-center justify-center h-full w-full">
                <div className="relative flex flex-col w-[93%] h-[93%] justify-between">
                  <div
                    className="h-full flex flex-col items-center p-[10px] text-[18px] justify-center bg-cover bg-no-repeat"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL
                      }/imgs/farm/monsterFarm/${convertImageFamrOfMonster(
                        dataMonster[0]?.monster_script_id,
                      )}.png)`,
                      clipPath:
                        'polygon(97% 100%, 100% 97%, 100% 3%, 97% 0%, 3% 0%, 0% 3%, 0% 97%, 3% 100%)',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 10,
                        left: 0,
                        marginLeft: '40px',
                      }}
                    >
                      <img
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: 'contain',
                        }}
                        src={`../imgs/logoMonster/${converImageCollection(
                          extractConvertMonsterSeed(
                            dataMonster[0]?.monster_script_id,
                          ).mainSeed,
                        )}.png`}
                        alt=""
                      />
                    </div>
                    <div className="relative">
                      <Image
                        className="relative card__detail_img w-[80%] h-[80%] max-h-[350px] object-contain	ease-in-out duration-300 z-50	"
                        src={`${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/gif/${dataMonster[0]?.monster_script_id}.gif`}
                        alt=""
                        fallback={`${process.env.PUBLIC_URL}/imgs/common/aries_avatar.gif`}
                        preview={false}
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={14}>
            <div
              style={{
                border: '1px solid rgb(20, 97, 203)',
                background: 'rgb(12, 41, 88)',
                padding: '10px 20px',
              }}
            >
              <div
                style={{
                  background: 'rgb(0, 15, 54)',
                  padding: '10px',
                  marginBottom: '10px',
                  boxShadow: '0px 0px 10px rgba(0, 150, 248, 0.6)',
                  borderRadius: '10px',
                }}
              >
                <Row gutter={24}>
                  <Col span={19} className="flex flex-wrap">
                    {seed.map((item) => (
                      <div
                        key={item}
                        className="w-[8.5%] mb-[2px] flex justify-center hover-border cursor-pointer"
                        onClick={() => scrollToMonster(item)}
                      >
                        <img
                          className="w-[45px] h-[45px]"
                          src={`${process.env.PUBLIC_URL}/imgs/seed/${item}.png`}
                          alt=""
                        />
                      </div>
                    ))}
                  </Col>
                  <Col span={5}>
                    <div className="mb-[14%] flex flex-col items-center border border-white bg-black rounded-[8px]">
                      <div>Collection Rate</div>
                      <div className="text-[20px]">
                        {Number.isInteger((totalSeed / 142) * 100)
                          ? (totalSeed / 142) * 100
                          : ((totalSeed / 142) * 100).toFixed(0)}
                        %
                      </div>
                    </div>

                    <div className="flex justify-center text-[16px]">
                      {totalSeed}/142
                    </div>
                  </Col>
                </Row>
              </div>

              <div
                className="guildInfo"
                style={{
                  background: 'rgb(0, 15, 54)',
                  padding: '20px',
                  overflow: 'auto',
                  maxHeight: '330px',
                  boxShadow: '0px 0px 10px rgba(0, 150, 248, 0.6)',
                  borderRadius: '10px',
                }}
              >
                {monsterSeedWithActiveCount.map((item) => (
                  <div
                    key={item.id}
                    ref={(el) => (monsterRefs.current[item.id] = el)}
                    style={{
                      background: 'rgb(9, 39, 87)',
                      padding: '10px',
                      border: '1px solid rgb(0, 145, 248)',
                      marginBottom: '5px',
                    }}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex items-center">
                        <img
                          className="w-[30px] h-[30px]"
                          src={`${process.env.PUBLIC_URL}/imgs/seed/${item.id}.png`}
                          alt=""
                        />
                        <span className="ml-2 text-[18px] font-[Rexliafree]">
                          {item.name}
                        </span>
                      </div>
                      <div className="text-end ml-auto font-[Rexliafree]">
                        {item?.activeCount}/{item.dataBase?.length}
                      </div>
                    </div>
                    <div
                      className={`flex flex-wrap mt-2 w-[100%] ${
                        item.dataBase?.length < 8
                          ? 'justify-center'
                          : 'justify-start'
                      }`}
                    >
                      {item.dataBase?.map((monsterItem) => {
                        const isMonsterActive = listMonsterAddressWallet?.some(
                          (dataItem) =>
                            dataItem.monster_script_id === monsterItem,
                        )

                        return (
                          <div
                            key={monsterItem}
                            className={
                              isMonsterActive
                                ? 'monster_imgActive'
                                : 'monster-img'
                            }
                            onClick={
                              isMonsterActive
                                ? () => handleMonsterClick(monsterItem)
                                : null
                            }
                          >
                            <img
                              className="w-[80px] h-[80px] object-contain rounded-[3px]"
                              alt=""
                              src={`${
                                process.env.PUBLIC_URL
                              }/imgs/dataBaseMonster/${convertImageMonsterSeed(
                                monsterItem,
                              )}.png`}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-2 text-[20px] font-[Rexliafree] information ">
                -Informattion-
                <Row gutter={24}>
                  <Col span={14} xl={14} md={24} sm={24} xs={24}>
                    <div className="flex text-[18px]">
                      <div>Project</div>
                      <div className="ml-[18%]">
                        {convertSeed(dataMonster[0]?.main_seed)}
                      </div>
                    </div>
                    <div className="flex text-[18px]">
                      <div>Max Overall</div>
                      <div className="ml-[5%] flex">
                        {dataMonster[0]?.overall?.toFixed(0)}
                        <img
                          className="ml-2"
                          src={`../svgs/rankTagText/${getRankOverall(
                            dataMonster[0]?.overall,
                          )}.svg`}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={9} xl={9} md={24} sm={24} xs={24}>
                    <div className="flex text-[18px]">
                      <div>Obtaining</div>
                      <div className="ml-[18%]">{obtaining}</div>
                    </div>
                    <div className="flex text-[18px]">
                      <div>Raising</div>
                      <div className="ml-[27%]">{raising}</div>
                    </div>
                  </Col>
                </Row>
                <div className="flex justify-between mt-2">
                  {dataMonster && (
                    <>
                      <a
                        href={`${process.env.REACT_APP_BASE_URL_WEB}monster/${dataMonster[0]?.monster_nft_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn_detail"
                      >
                        Detail
                      </a>
                    </>
                  )}

                  <div className="btn_return" onClick={onClose}>
                    Return
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

ModalDatabase.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
  user: PropTypes.string,
  address: PropTypes.string,
}

export default ModalDatabase
