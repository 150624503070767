import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getListWhiteListsSuccess,
  getListWhiteListsFailed,
  GET_LIST_WHITELIST_START,
} from '../../actions/whiteList'
import { api } from '~/services/api'

/**
 * get list whitelist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListWhiteListsApi = (props) => {
  var uri = `/sponsor-tournament-whitelist`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetWhiteLists(action) {
  try {
    const response = yield call(getListWhiteListsApi, action?.payload)
    yield put(getListWhiteListsSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListWhiteListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list whitelist
 */
export default function* watchGetWhiteLists() {
  yield takeLatest(GET_LIST_WHITELIST_START, doGetWhiteLists)
}
