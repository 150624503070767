export const GET_TONGLE_WHITELIST_START = 'GET_TONGLE_WHITELIST_START'
export const GET_TONGLE_WHITELIST_SUCCESS = 'GET_TONGLE_WHITELIST_SUCCESS'
export const GET_TONGLE_WHITELIST_FAILED = 'GET_TONGLE_WHITELIST_FAILED'

export const CHANGE_TONGLE_WHITELIST_START = 'CHANGE_TONGLE_WHITELIST_START'
export const CHANGE_TONGLE_WHITELIST_SUCCESS = 'CHANGE_TONGLE_WHITELIST_SUCCESS'
export const CHANGE_TONGLE_WHITELIST_FAILED = 'CHANGE_TONGLE_WHITELIST_FAILED'

export const GET_LIST_WHITELIST_START = 'GET_LIST_WHITELIST_START'
export const GET_LIST_WHITELIST_SUCCESS = 'GET_LIST_WHITELIST_SUCCESS'
export const GET_LIST_WHITELIST_FAILED = 'GET_LIST_WHITELIST_FAILED'

export const GET_LIST_ADD_WHITELIST_START = 'GET_LIST_ADD_WHITELIST_START'
export const GET_LIST_ADD_WHITELIST_SUCCESS = 'GET_LIST_ADD_WHITELIST_SUCCESS'
export const GET_LIST_ADD_WHITELIST_FAILED = 'GET_LIST_ADD_WHITELIST_FAILED'

export const CREATE_WHITELIST_START = 'CREATE_WHITELIST_START'
export const CREATE_WHITELIST_SUCCESS = 'CREATE_WHITELIST_SUCCESS'
export const CREATE_WHITELIST_FAILED = 'CREATE_WHITELIST_FAILED'

export const DELETE_WHITELIST_START = 'DELETE_WHITELIST_START'
export const DELETE_WHITELIST_SUCCESS = 'DELETE_WHITELIST_SUCCESS'
export const DELETE_WHITELIST_FAILED = 'DELETE_WHITELIST_FAILED'

//-------------------------WhiteList------------------------//
/**
 * [1]
 * get list WhiteList start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getTongleWhiteLists(payload, onSuccess, onError) {
  return {
    type: GET_TONGLE_WHITELIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list WhiteList success action
 * @param {*} payload
 * @returns {object}
 */
export function getTongleWhiteListsSuccess(payload) {
  return {
    type: GET_TONGLE_WHITELIST_SUCCESS,
    payload: payload,
  }
}

/**
 * get list WhiteList failed action
 * @returns {object}
 */
export function getTongleWhiteListsFailed() {
  return {
    type: GET_TONGLE_WHITELIST_FAILED,
  }
}

/**
 * [2]
 * post toggle WhiteList start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function changeTongleWhiteLists(payload, onSuccess, onError) {
  return {
    type: CHANGE_TONGLE_WHITELIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 *  post toggle WhiteList success action
 * @param {*} payload
 * @returns {object}
 */
export function changeTongleWhiteListSuccess(payload) {
  return {
    type: CHANGE_TONGLE_WHITELIST_SUCCESS,
    payload: payload,
  }
}

/**
 *  post toggle WhiteList failed action
 * @returns {object}
 */
export function changeTongleWhiteListFailed() {
  return {
    type: CHANGE_TONGLE_WHITELIST_FAILED,
  }
}

/**
 * [3]
 * get list whitelist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListWhiteLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_WHITELIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list whitelist success action
 * @param {*} payload
 * @returns {object}
 */
export function getListWhiteListsSuccess(payload) {
  return {
    type: GET_LIST_WHITELIST_SUCCESS,
    payload: payload,
  }
}

/**
 * get list whitelist failed action
 * @returns {object}
 */
export function getListWhiteListsFailed() {
  return {
    type: GET_LIST_WHITELIST_FAILED,
  }
}

/**
 * [4]
 * get list add whitelist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListAddWhiteLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_ADD_WHITELIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list add whitelist success action
 * @param {*} payload
 * @returns {object}
 */
export function getListAddWhiteListsSuccess(payload) {
  return {
    type: GET_LIST_ADD_WHITELIST_SUCCESS,
    payload: payload,
  }
}

/**
 * get list add whitelist failed action
 * @returns {object}
 */
export function getListAddWhiteListsFailed() {
  return {
    type: GET_LIST_ADD_WHITELIST_FAILED,
  }
}

/**
 * [5]
 * create whitelist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createWhiteLists(payload, onSuccess, onError) {
  return {
    type: CREATE_WHITELIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * create whitelist success action
 * @param {*} payload
 * @returns {object}
 */
export function createWhiteListsSuccess(payload) {
  return {
    type: CREATE_WHITELIST_SUCCESS,
    payload: payload,
  }
}

/**
 * create whitelist failed action
 * @returns {object}
 */
export function createWhiteListsFailed() {
  return {
    type: CREATE_WHITELIST_FAILED,
  }
}

/**
 * [6]
 * delete whitelist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteWhiteLists(payload, onSuccess, onError) {
  return {
    type: DELETE_WHITELIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete whitelist success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteWhiteListsSuccess(payload) {
  return {
    type: DELETE_WHITELIST_SUCCESS,
    payload: payload,
  }
}

/**
 * delete whitelist failed action
 * @returns {object}
 */
export function deleteWhiteListsFailed() {
  return {
    type: DELETE_WHITELIST_FAILED,
  }
}

export default {
  getTongleWhiteLists,
  getTongleWhiteListsSuccess,
  getTongleWhiteListsFailed,

  changeTongleWhiteLists,
  changeTongleWhiteListSuccess,
  changeTongleWhiteListFailed,

  getListWhiteLists,
  getListWhiteListsSuccess,
  getListWhiteListsFailed,

  getListAddWhiteLists,
  getListAddWhiteListsSuccess,
  getListAddWhiteListsFailed,

  createWhiteLists,
  createWhiteListsSuccess,
  createWhiteListsFailed,

  deleteWhiteLists,
  deleteWhiteListsSuccess,
  deleteWhiteListsFailed,
}
