import React, { useEffect, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import { NOTIFICATION_DURATION } from '../constants'
import { usePools } from '~/views/app/redux/hooks/usePools'
import { LoadingOutlined } from '@ant-design/icons'
export default function ResetNFTPoolCommon(props) {
  const {
    t = {},
    title = null,
    action = {},
    dataPool,
    refreshData,
    checlStatusReset,
  } = props

  const [isLoadingConfirmReset, setIsloadingConfirmReset] = useState(false)

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
    backgroundColor: 'transparent',
    border: 'none',
  }

  const {
    actions: actionsPool,
    data: { dataprocessId },
  } = usePools()
  useEffect(() => {
    if (dataprocessId?.processId) {
      localStorage.setItem('dataprocessId', JSON.stringify(dataprocessId))
    }
  }, [dataprocessId])

  const handleSubmit = async () => {
    setIsloadingConfirmReset(true)
    try {
      await Promise.all([
        new Promise((resolve, reject) => {
          actionsPool?.deleteNFTgame({ cycle: dataPool[0]?.name }, (res) => {
            if (res) resolve()
            else reject(new Error('deleteNFTgame failed'))
          })
        }),
        actionsPool?.deleteNFTmarket(),
      ])
      onSuccess()
    } catch (error) {
      console.error(error)
    } finally {
      setIsloadingConfirmReset(false)
    }
  }

  const onSuccess = () => {
    // notification.success({
    //   message: t('common.message.deleteSuccessfully'),
    //   placement: 'topRight',
    //   duration: NOTIFICATION_DURATION,
    // })
    action?.handleCancel()
    refreshData()
  }

  return (
    <>
      <Modal
        centered
        title={
          <h3 className="flex justify-center text-xl font-normal text-[#fff]">
            {title ?? t('common.titleConfirmResetNFTModal')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            {checlStatusReset ? (
              <>
                <Button
                  key="ok"
                  type="primary"
                  onClick={action?.handleCancel}
                  style={okButtonStyle}
                  loading={isLoadingConfirmReset}
                >
                  {t('cancel_sell.close')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  key="cancel"
                  onClick={action?.handleCancel}
                  style={cancelButtonStyle}
                >
                  {t('cancel_sell.cancel')}
                </Button>
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => handleSubmit()}
                  style={okButtonStyle}
                  loading={isLoadingConfirmReset}
                >
                  {t('cancel_sell.ok')}
                </Button>
              </>
            )}
          </div>,
        ]}
      >
        <div className="text-center text-[16px]">
          {checlStatusReset
            ? t('common.messConfirmResstNFTModalLoading')
            : t('common.messConfirmResstNFTModal')}
        </div>
        {checlStatusReset && (
          <div className="flex justify-center mt-4 text-[25px]">
            <LoadingOutlined />
          </div>
        )}
      </Modal>
    </>
  )
}
