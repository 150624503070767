import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, notification, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import configs from '~/configs'
import { DeleteFilled } from '@ant-design/icons'
import { useBlackLists } from '~/views/app/redux/hooks/useBlackList'
import { NOTIFICATION_DURATION } from '~/common/constants'
import DeleteUserBlackListCommon from '~/common/hooks/useDeleteBlackList'

const BlackList = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    user: {},
  })
  const {
    actions,
    data: { listBlackList, isLoadingGetListBlackList, listAddBlackList, total },
  } = useBlackLists()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()
  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }
  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementUsers.form.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (_, data) => {
        return <div className="">{data?.user_id?.user_name}</div>
      },
    },
    {
      title: t('managementUsers.form.walletAddress'),
      dataIndex: 'wallet_address',
      key: 'wallet_address',
      render: (wallet_address) => {
        let link = `${configs.NETWORK.scan}address/${wallet_address}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={wallet_address}
            style={{ color: '#40A9FF' }}
          >
            {wallet_address}
          </a>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              // onClick={() => onShowModalForm(data?._id, data)}
              onClick={() => {
                onRemove(data)
              }}
            />
          </Space>
        )
      },
    },
  ]
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  //Refresh data
  const refreshData = () => {
    const params = {
      pageSize: pageSize,
      page,
    }
    actions.getListBlackLists(params)
    actions.getListAddBlackLists()
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  const generateItemList = (listAddBlackList) =>
    listAddBlackList?.map((item) => ({
      key: item?._id,
      label: (
        <Row>
          <Col span={24}>Name: {item?.user_name}</Col>
          <Col span={24}>Wallet address: {item?.address_wallet}</Col>
        </Row>
      ),
      value: item?.address_wallet,
      name: item?.user_name,
    }))

  const handleAddUserBlackList = async (value) => {
    const params = {
      wallet_address: value,
    }
    try {
      await actions.createBlackLists(params)
      setTimeout(() => {
        refreshData()
        formSearch.resetFields()
        notification.success({
          message: t('common.message.createUserSuccessfully'),
          placement: 'topRight',
          duration: NOTIFICATION_DURATION,
        })
      }, 500)
    } catch (error) {
      //eslint-disable-next-line no-console
      console.log(error)
    }
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <div className="flex justify-center align-center">
            <Form
              onFinish={handleSearch}
              className="w-[80%] "
              form={formSearch}
              translate="yes"
              autoComplete="off"
              size="middle"
              validateTrigger={['onBlur', 'onChange']}
              requiredMark={false}
            >
              <Form.Item
                className="w-full"
                label={
                  <span className="font__M__plus font-bold">
                    {t('common.add')}
                  </span>
                }
                name="keyword"
                labelAlign="left"
              >
                <Select
                  className="w-full addUserList"
                  onChange={handleAddUserBlackList}
                  options={generateItemList(listAddBlackList)}
                  placeholder={t('common.add')}
                  showSearch
                  // filterOption={(input, option) =>
                  //   option?.name?.toLowerCase().indexOf(input?.toLowerCase()) >=
                  //   0
                  // }
                  filterOption={(input, option) => {
                    const nameMatch = option?.name
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                    const valueMatch = option?.value
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                    return nameMatch || valueMatch
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end"></div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listBlackList}
        loading={isLoadingGetListBlackList}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      <DeleteUserBlackListCommon
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataUserBlackList={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default BlackList
