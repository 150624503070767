import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createWhiteListsSuccess,
  createWhiteListsFailed,
  CREATE_WHITELIST_START,
} from '../../actions/whiteList'
import { api } from '~/services/api'

/**
 * create whitelist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createWhiteListsApi = (props) => {
  var uri = `/sponsor-tournament-whitelist`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doCreateWhiteLists(action) {
  try {
    const response = yield call(createWhiteListsApi, action?.payload)
    yield put(createWhiteListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(createWhiteListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch create whitelist
 */
export default function* watchCreateWhiteLists() {
  yield takeLatest(CREATE_WHITELIST_START, doCreateWhiteLists)
}
