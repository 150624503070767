import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteNFTgameSuccess,
  deleteNFTgameFailed,
  DELETE_API_GAME_MONSTER_NFT_START,
} from '../../actions/pools'
import { api } from '~/services/api/gameApi'

/**
 *delete NFT pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const resetNFTGameApi = (props) => {
  var uri = `/api/game/monster/delete-nft-regenerate-cycle`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doResetNFTPools(action) {
  try {
    const response = yield call(resetNFTGameApi, action?.payload)
    yield put(deleteNFTgameSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteNFTgameFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watchdelete NFT pools
 */
export default function* watchResetNFTPools() {
  yield takeLatest(DELETE_API_GAME_MONSTER_NFT_START, doResetNFTPools)
}
