import {
  GET_LIST_USER_START,
  GET_LIST_USER_SUCCESS,
  GET_LIST_USER_FAILED,
  GET_DETAIL_USER_START,
  GET_DETAIL_USER_SUCCESS,
  GET_DETAIL_USER_FAILED,
  GET_USER_BY_WALLET_START,
  GET_USER_BY_WALLET_SUCCESS,
  GET_USER_BY_WALLET_FAILED,
  GET_USER_SCORE_BY_WALLET_START,
  GET_USER_SCORE_BY_WALLET_SUCCESS,
  GET_USER_SCORE_BY_WALLET_FAILED,
  GET_BALANCEPOC_START,
  GET_BALANCEPOC_SUCCESS,
  GET_BALANCEPOC_FAILED,
  GET_LIST_SCORE_ADDRESS_START,
  GET_LIST_SCORE_ADDRESS_SUCCESS,
  GET_LIST_SCORE_ADDRESS_FAILED,
  GET_LIST_MONSTER_ADDRESS_START,
  GET_LIST_MONSTER_ADDRESS_SUCCESS,
  GET_LIST_MONSTER_ADDRESS_FAILED,
  GET_LIST_SNAPSHOT_ADDRESS_START,
  GET_LIST_SNAPSHOT_ADDRESS_SUCCESS,
  GET_LIST_SNAPSHOT_ADDRESS_FAILED,
  GET_RANKING_ACHIEVEMENT_START,
  GET_RANKING_ACHIEVEMENT_SUCCESS,
  GET_RANKING_ACHIEVEMENT_FAILED,
} from '../actions/user'
import { addKeyToList } from '~/helpers/transform-data'

const initialState = {
  isLoading: false,
  listUser: [],
  listUserByWallet: [],
  listScoreByWallet: [],
  listScoreAddress: [],
  listMonsterAddressWallet: [],
  userDetail: {},
  balancePoc: {},
  rankingAchievement: [],
  total: null,
}
/**
 * user reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function userManagement(state = initialState, action) {
  switch (action.type) {
    // START
    case GET_LIST_USER_START:
    case GET_DETAIL_USER_START:
    case GET_USER_BY_WALLET_START:
    case GET_USER_SCORE_BY_WALLET_START:
    case GET_BALANCEPOC_START:
    case GET_LIST_SCORE_ADDRESS_START:
    case GET_LIST_MONSTER_ADDRESS_START:
    case GET_LIST_SNAPSHOT_ADDRESS_START:
    case GET_RANKING_ACHIEVEMENT_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUser: addKeyToList(
          action.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action.payload.total,
      }

    case GET_DETAIL_USER_SUCCESS:
      return {
        ...state,
        userDetail: action?.payload,
        isLoading: false,
      }
    case GET_USER_BY_WALLET_SUCCESS:
      return {
        ...state,
        listUserByWallet: action?.payload,
        isLoading: false,
      }
    case GET_USER_SCORE_BY_WALLET_SUCCESS:
      return {
        ...state,
        listScoreByWallet: action?.payload?.user_score,
        isLoading: false,
      }
    case GET_BALANCEPOC_SUCCESS:
      return {
        ...state,
        balancePoc: action?.payload?.balance,
        isLoading: false,
      }
    case GET_LIST_SCORE_ADDRESS_SUCCESS:
      return {
        ...state,
        listScoreAddress: action?.payload?.data,
        isLoading: false,
      }
    case GET_LIST_MONSTER_ADDRESS_SUCCESS:
      return {
        ...state,
        listMonsterAddressWallet: action?.payload?.data,
        isLoading: false,
      }
    case GET_LIST_SNAPSHOT_ADDRESS_SUCCESS:
      return {
        ...state,
        listSnapshot: action?.payload?.data?.score_snapshot,
        isLoading: false,
      }
    case GET_RANKING_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        rankingAchievement: action?.payload?.ranked_match_history,
        isLoading: false,
      }

    // FAILED
    case GET_LIST_USER_FAILED:
    case GET_DETAIL_USER_FAILED:
    case GET_USER_BY_WALLET_FAILED:
    case GET_USER_SCORE_BY_WALLET_FAILED:
    case GET_BALANCEPOC_FAILED:
    case GET_LIST_SCORE_ADDRESS_FAILED:
    case GET_LIST_MONSTER_ADDRESS_FAILED:
    case GET_LIST_SNAPSHOT_ADDRESS_FAILED:
    case GET_RANKING_ACHIEVEMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
