export const DATE_FORMAT_YYYYMMdd = 'YYYYMMdd'
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD'
export const DATE_FORMAT_YYYY_MM_dd = 'YYYY-MM-dd'
export const DATE_FORMAT_YYYY_MM_dd_Combined = 'YYYYMMddHHmm'
export const DATE_FORMAT_YYYY_MM_dd_HH_mm = 'YYYY-MM-dd HH:mm'
export const DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm = 'YYYY-MM-dd HH:mm:ss'
export const DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 = 'YYYY/MM/DD HH:mm:ss'
export const DATE_FORMAT_DD_MM_YYYY = 'DD-MM-YYYY'
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = 'DD.MM.YYYY'
export const DATE_FORMAT_mm_dd_YYYY_WITH_SLASH = 'MM/dd/YYYY'
export const DATE_FORMAT_m_d_YYYY_WITH_SLASH = 'M/d/YYYY'
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = 'DD/MM/YYYY'
export const DATE_FORMAT_dd_MMM = 'ddMMM'
export const DATE_FORMAT_dd_MM_yy = 'ddMMyy'
export const DATE_FORMAT_dd_MMM_YYYY = 'ddMMMYYYY'
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = 'DDMMYYYY'
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss = "YYYY-MM-dd'T'HH:mm:ss"
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz = "YYYY-MM-dd'T'HH:mm:ss.SSS"
export const DATE_FORMAT_DD_MM_YYYY_HH_mm = 'dd-MM-YYYY HH:mm'
export const DATE_FORMAT_HH_mm = 'HH:mm'
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ"
export const DATE_FORMAT_USAGE_TIME = 'HH:mm'
export const DATE_FORMAT_TIME = 'HH:mm:ss'
export const DATE_FORMAT_TEST_TIME = "mm's"
export const YEAR_FORMAT = 'YYYY'
export const formatTimeMinuteSecond = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}
export const getWeekTimestamps = () => {
  const currentDate = new Date()
  const startOfWeek = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate() -
        (currentDate.getUTCDay() === 0 ? 6 : currentDate.getUTCDay() - 1),
    ),
  )
  startOfWeek.setUTCHours(0, 0, 0, 0)
  const fromDateWeek = Math.floor(startOfWeek.getTime() / 1000)
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6)
  endOfWeek.setUTCHours(23, 59, 59, 999)
  const toDateWeek = Math.floor(endOfWeek.getTime() / 1000)

  return { fromDateWeek, toDateWeek }
}
export function getCurrentWeekRange() {
  const selectTime = new Date()
  const day = selectTime.getDate()
  const month = selectTime.getMonth() + 1 // JavaScript months are 0-indexed
  const year = selectTime.getFullYear()

  let startDate, endDate

  if (day >= 1 && day <= 7) {
    startDate = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0))
    endDate = new Date(Date.UTC(year, month - 1, 7, 23, 59, 59))
  } else if (day >= 8 && day <= 14) {
    startDate = new Date(Date.UTC(year, month - 1, 8, 0, 0, 0))
    endDate = new Date(Date.UTC(year, month - 1, 14, 23, 59, 59))
  } else if (day >= 15 && day <= 21) {
    startDate = new Date(Date.UTC(year, month - 1, 15, 0, 0, 0))
    endDate = new Date(Date.UTC(year, month - 1, 21, 23, 59, 59))
  } else if (day >= 22 && day <= 28) {
    startDate = new Date(Date.UTC(year, month - 1, 22, 0, 0, 0))
    endDate = new Date(Date.UTC(year, month - 1, 28, 23, 59, 59))
  } else {
    // Increment the month for the next range
    const nextMonth = new Date(selectTime)
    nextMonth.setMonth(nextMonth.getMonth() + 1)

    const nextMonthYear = nextMonth.getFullYear()
    const nextMonthMonth = nextMonth.getMonth()

    startDate = new Date(Date.UTC(nextMonthYear, nextMonthMonth, 1, 0, 0, 0))
    endDate = new Date(Date.UTC(nextMonthYear, nextMonthMonth, 7, 23, 59, 59))
  }

  return {
    startTimestamp: Math.floor(startDate.getTime() / 1000), // Convert to Unix timestamp
    endTimestamp: Math.floor(endDate.getTime() / 1000), // Convert to Unix timestamp
  }
}
export const getStartOfMonthUTC = () => {
  const now = new Date()
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1, 0, 0, 0) / 1000
}

export const getEndOfMonthUTC = () => {
  const now = new Date()
  return (
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 23, 59, 59) / 1000
  )
}
