import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteWhiteListsSuccess,
  deleteWhiteListsFailed,
  DELETE_WHITELIST_START,
} from '../../actions/whiteList'
import { api } from '~/services/api'

/**
 * delete whitelist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteWhiteListsApi = (params) => {
  const uri = `/sponsor-tournament-whitelist/${params}`
  return api.delete(uri)
}
/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteWhiteLists(action) {
  try {
    const response = yield call(deleteWhiteListsApi, action?.payload)
    yield put(deleteWhiteListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteWhiteListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete whitelist
 */
export default function* watchDeleteWhiteLists() {
  yield takeLatest(DELETE_WHITELIST_START, doDeleteWhiteLists)
}
