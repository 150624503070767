import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteNFTmarketSuccess,
  deleteNFTmarketFailed,
  DELETE_API_MARKET_MONSTER_NFT_START,
} from '../../actions/pools'
import { api } from '~/services/api/blockchainApi'

/**
 * delete nft market api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const resetNFTMarketApi = (props) => {
  var uri = `/api/monster/reset-regen-count`
  return api.post(uri, props)
}
/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doResetNFTPoolsMarket(action) {
  try {
    const response = yield call(resetNFTMarketApi, action?.payload)
    yield put(deleteNFTmarketSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteNFTmarketFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete nft market
 */
export default function* watchResetNFTPoolsMarket() {
  yield takeLatest(DELETE_API_MARKET_MONSTER_NFT_START, doResetNFTPoolsMarket)
}
